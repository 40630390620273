<script setup lang="ts">
import IconSvgBase from './IconSvgBase.vue';

type Props = {
  strokeWidthRatio?: number;
};

const props = withDefaults(defineProps<Props>(), {
  strokeWidthRatio: 1,
});

const strokeWidth = computed(() => 3 * props.strokeWidthRatio);
</script>

<template>
  <IconSvgBase :view-width="20" :view-height="20" class="icon">
    <line
      :stroke-width="strokeWidth"
      x1="1"
      y1="1"
      x2="19"
      y2="19"
      class="icon__stroke"
    />
    <line
      :stroke-width="strokeWidth"
      x1="1"
      y1="19"
      x2="19"
      y2="1"
      class="icon__stroke"
    />
  </IconSvgBase>
</template>

<style lang="scss" scoped>
.icon {
  &__stroke {
    fill: transparent;
    stroke: currentColor;
  }
}
</style>

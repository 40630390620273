<script setup lang="ts">
import IconClose from '~theme/components/atoms/icons/IconClose.vue';

const emits = defineEmits<{
  click: [MouseEvent];
}>();

const onClick = (event: MouseEvent) => {
  emits('click', event);
};
</script>

<template>
  <button :class="$style.button" @click="onClick">
    <IconClose :class="$style.button__icon" />
  </button>
</template>

<style lang="scss" module>
.button {
  padding: 0.1em;
  border: 0;
  background-color: transparent;

  &:hover {
    cursor: pointer;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  &__icon {
    color: inherit;
    font-size: inherit;
  }
}
</style>
